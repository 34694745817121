import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAbortController} from "../hooks/useAbortController";

export const Logout = () => {
    const navigate = useNavigate()
    const abortController = useAbortController();

    useEffect(() => {
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('expires_in');

        const logoutUrl = `${window._env_.REACT_APP_APIGATEWAY}/auths/oauth2/logout`;
        fetch(logoutUrl, {
            method: 'POST',
            credentials: "include",
            signal: abortController.signal
        })
            .then(() => navigate('/'))

    }, [abortController.signal, navigate]);

    return null;
};