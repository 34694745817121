export const SIGN_UP_DIALOG_STARTED = 'signUp/dialogStarted';
export const SIGN_UP_STARTED = 'signUp/started';
export const SIGN_UP_FINISHED = 'signUp/finished';
export const SIGN_UP_FAILED = 'signUp/failed';
export const CONFIRM_SIGN_UP_STARTED = 'signUp/confirmStarted';
export const CONFIRM_SIGN_UP_FINISHED = 'signup/confirmFinished';
export const CONFIRM_SIGN_UP_FAILED = 'signUp/confirmFailed';
export const RESEND_CONFIRMATION_CODE_STARTED = 'signUp/resendCodeStarted';
export const RESEND_CONFIRMATION_CODE_FINISHED = 'signup/resendCodeFinished';
export const RESEND_CONFIRMATION_CODE_FAILED = 'signUp/resendCodeFailed';
export const SIGN_UP_STEP_CHANGED = 'signUp/stepChanged';

export const startSignUpGuide = () => dispatch => {
    dispatch({type: SIGN_UP_DIALOG_STARTED})
};

export const signUp = ({email, password, givenName, familyName}) => dispatch => {
    dispatch({type: SIGN_UP_STARTED});

    const data = {
        username: email,
        password: password,
        givenName: givenName,
        familyName: familyName
    };

    userpoolFetch({operation: 'sign-up', data: data})
        .then(() => {
            dispatch({type: SIGN_UP_FINISHED});
            dispatch(codeVerification(email))
        })
        .catch(() => dispatch({
            type: SIGN_UP_FAILED,
            email: email
        }))
};

export const confirmRegistration = ({email, verificationCode}) => dispatch => {
    dispatch({type: CONFIRM_SIGN_UP_STARTED});

    const data = {
        username: email,
        verificationCode: verificationCode
    };

    userpoolFetch({operation: 'confirm-sign-up', data: data})
        .then(() => dispatch({type: CONFIRM_SIGN_UP_FINISHED}))
        .catch(() => dispatch({type: CONFIRM_SIGN_UP_FAILED}))

};

export const resendConfirmationCode = email => dispatch => {
    dispatch({type: RESEND_CONFIRMATION_CODE_STARTED});

    const data = {
        username: email
    };

    userpoolFetch({operation: 'resend-confirmation-code', data: data})
        .then(() => {
            dispatch({type: RESEND_CONFIRMATION_CODE_FINISHED});
            dispatch(codeVerification(email));
        })
        .catch(() => dispatch({type: RESEND_CONFIRMATION_CODE_FAILED}))
};

export const resendCode = email => dispatch => {
    dispatch({
        type: SIGN_UP_STEP_CHANGED,
        step: 'resendCode',
        email: email
    })
};

export const codeVerification = email => dispatch => {
    dispatch({
        type: SIGN_UP_STEP_CHANGED,
        step: 'codeVerification',
        email: email
    })
};

const userpoolFetch = ({operation, data}) => {
    return fetch(`${window._env_.REACT_APP_APIGATEWAY}/auths/userpool/${operation}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
        .then(response => response.ok ? Promise.resolve() : Promise.reject())
};