import React, {useState} from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Slide from "@mui/material/Slide";
import {useLoginTranslation} from "../../login/loginContext";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

export const GdprDialog = () => {
    const [open, setOpen] = useState(!localStorage.getItem('terms_accepted'));
    const t = useLoginTranslation()

    const url = 'https://kulturit.org/personvernerklaring';

    const clickHandler = () => {
        localStorage.setItem('terms_accepted', 'true');
        setOpen(false)
    };

    if(!open) {
        return null
    }

    return (
        <Dialog
            open={open}
            scroll={'paper'}
            aria-labelledby={'gdpr-title'}
            aria-describedby={'gdpr-description'}
            TransitionComponent={Transition}
        >
            <DialogTitle id={'gdpr-titel'}>
                {t(
                    'title',
                    'Personvern',
                    {
                        ns: 'login.gdpr'
                    }
                )}
            </DialogTitle>
            <DialogContent dividers={false}>
                <DialogContentText
                    id={'gdpr-description'}
                    tabIndex={-1}
                    >
                    {t(
                        'body',
                        'eKultur autentisering er en felles innloggingsløsning for våre produkter, hvor du kun' +
                        ' trenger å logge inn en gang, et sted for å få tilgang til dine tjenester. For at dette skal' +
                        ' fungere bruker vi en informasjonskapsel (cookie) for å holde styr på hvem du er. Ved å' +
                        ' trykke "GODTA" godkjenner du bruken av cookies og personvernerklæringen for eKultur.',
                        {
                            ns: 'login.gdpr'
                        }
                    )}
                </DialogContentText>
                <DialogContentText>
                    <Link href={url} onClick={() => null} target={'_blank'} underline="hover">
                        {t(
                            'readMore',
                            'Les mer',
                            {
                                ns: 'login.gdpr'
                            }
                        )}
                    </Link>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={clickHandler} color={'primary'} >
                    {t(
                        'accept',
                        'Godta',
                        {
                            ns: 'login.gdpr'
                        }
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};