import React, {Fragment} from 'react';
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import {Link as RouteLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {signUp} from "../signUpAction";
import * as Yup from "yup";
import {FormProvider} from "../../form/formContext";
import {Stack} from "@mui/material";
import {UserdataForm} from "../UserdataForm";
import {PersonAdd} from "@mui/icons-material";
import {FormSubmit} from "../../form/FormSubmit";
import FormHelperText from "@mui/material/FormHelperText";
import {InitializeForm} from "../../login/username-password/InitializeForm";
import {SamlProvider} from "../../login/samlContext";
import {WithSamlIdp} from "../../login/WithSamldp";
import {useSamlLogin} from "../../login/useSamlLogin";
import {useLoginTranslation} from "../../login/loginContext";


export const UserdataStep = () => {
    const t = useLoginTranslation()
    const dispatch = useDispatch();
    const signUpState = useSelector(state => state.signUp);
    const samlLogin = useSamlLogin()

    const validationSchema = Yup.object().shape({
        username: Yup.string()
            .email(
                t('validEmail', 'Må være en gyldig epostadresse')
            )
            .required(
                t('emailHelperText', 'Skriv inn e-posten din')
            ),
        givenName: Yup.string()
            .required(
                t('givenNameHelperText', 'Skriv inn fornavnet ditt')
            ),
        familyName: Yup.string()
            .required(
                t('givenNameHelperText', 'Skriv inn etternavnet ditt')
            ),
        newPassword: Yup.string()
            .required(
                t('passwordHelperText', 'Skriv inn passordet ditt', {ns: 'login.passwordForm'})
            )
            .min(
                8,
                t('tooShort', 'Passordet må være minimum {{minLength}} tegn', {minLength: 8, ns: 'login.passwordForm'})
            )
            .matches(
                window._env_.REACT_APP_PASSWORD_PATTERN,
                t('patternMismatch', 'Passordet må inneholde store og små bokstaver og tall.', {ns: 'login.passwordForm'})
            ),
        repeatPassword: Yup.string()
            .required(
                t('repeatHelperText', 'Gjenta nøyaktig det samme passordet', {ns: 'login.passwordForm'})
            )
            .oneOf([Yup.ref('newPassword'), null],
                t('repeatMismatch', 'Passordene er ikke like', {ns: 'login.passwordForm'})
            )
    })

    const submitHandler = formData => {
        if (formData.samlDomain) {
            samlLogin(formData.samlDomain)
        } else {
            dispatch(signUp({
                email: formData.username.toLowerCase(),
                password: formData.newPassword,
                givenName: formData.givenName,
                familyName: formData.familyName,
            }))
        }
    }

    return (
        <FormProvider
            schema={validationSchema}
            onSubmit={submitHandler}
        >
            <InitializeForm/>
            <SamlProvider>
                <WithSamlIdp>
                    <Stack
                        spacing={2}
                        alignItems={"stretch"}
                        sx={{
                            width: "100%",
                            mt: 2
                        }}
                    >
                        <Typography variant='subtitle1' color='textPrimary'>
                            {t('login.newUser:title', 'Opprett konto')}
                        </Typography>
                        <UserdataForm
                            disabled={false}
                            t={t}
                        />
                        <FormHelperText
                            error={signUpState.failed}
                        >
                            {signUpState.failed ?
                                <Fragment>
                                    {t(
                                        'signUpError',
                                        'Vi klarte ikke å opprette en konto for deg. Er du allerede registrert?',
                                        {ns: 'login.newUser'}
                                    )}
                                    <Link
                                        to={`/forgot-password/${signUpState.email}`}
                                        component={RouteLink}
                                        underline="hover"
                                    >
                                        {t('forgotPassword', 'Glemt passordet?', {ns: 'login.newUser'})}
                                    </Link>
                                </Fragment>
                                : ''
                            }
                        </FormHelperText>
                        <FormSubmit
                            loadingButton={true}
                            loading={signUpState.inProgress}
                            loadingPosition={"start"}
                            disabled={signUpState.inProgress}
                            variant={"contained"}
                            startIcon={<PersonAdd/>}
                            sx={{
                                mt: 2
                            }}
                        >
                            {t('signUpButton', 'Opprett konto', {ns: 'login.newUser'})}
                        </FormSubmit>
                        <Stack
                            direction={"row"}
                            alignItems={"flex-start"}
                            justifyContent={'flex-end'}
                        >
                            <Typography>
                                {t('login.newUser:linkContext', 'Har du allerede en konto?')}
                                <Link
                                    to='/'
                                    sx={{
                                        margin: 1
                                    }}
                                    component={RouteLink}
                                    underline="hover">
                                    {t('login.newUser:link', 'Logg inn')}
                                </Link>
                            </Typography>
                        </Stack>
                    </Stack>
                </WithSamlIdp>
            </SamlProvider>
        </FormProvider>
    )
}