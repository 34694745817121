import {FormProvider} from "../form/formContext";
import * as Yup from "yup";
import {useLoginState, useLoginTranslation} from "./loginContext";
import {TextFieldForm} from "../form/TextFieldForm";
import React, {useState} from "react";
import {Form} from "../form/Form";
import Box from "@mui/material/Box";
import LoginIcon from "@mui/icons-material/Login";
import {FormSubmit} from "../form/FormSubmit";
import {v4 as uuidv4} from "uuid";
import {FormHelperText} from "@mui/material";

export const MFAForm = () => {
    const t = useLoginTranslation()
    const {
        state,
        responseType,
        clientId,
        redirectUri
    } = useLoginState()

    const [lastAttemptFailed, setLastAttemptFailed] = useState(false)

    const handleSubmit = formData => {
        const url = `${window._env_.REACT_APP_APIGATEWAY}/auths/oauth2/mfa`;

        const data = new URLSearchParams();
        data.append('authentication_code', formData.passcode);
        data.append('_csrf', uuidv4());
        data.append('state', state);
        data.append('response_type', responseType)
        data.append('redirect_uri', redirectUri);
        if(clientId) {
            data.append('client_id', clientId);
        }
        console.debug(data.toString())

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            credentials: 'include',
            body: data
        }).then(response => {
            if(response.ok) {
                return response.text();
            } else {
                throw Error('Login failed');
            }
        })
            .then(data => {
                if(data) {
                    window.location.href = data
                }
            })
            .catch(e => {
                setLastAttemptFailed(true)
                console.error(e);
            });
    }

    const validationSchema = Yup.object().shape({
        passcode: Yup.string()
            .required(t('passcodeHelperText', 'Kontoen din er beskyttet med tofaktorautentisering. Skriv inn en kode for å fortsette.')),
    })

    return (
        <Box
            sx={{
                mt: 2,
                mb: 2,
                width: "100%"
            }}
        >
            <FormProvider
                schema={validationSchema}
                onSubmit={handleSubmit}
            >
                <Form>
                    <TextFieldForm
                        formKey={'passcode'}
                        label={t('passcodeLabel', 'Engangskode')}
                        ariaLabel={t('passcodeHelperText', 'Kontoen din er beskyttet med tofaktorautentisering. Skriv inn en kode for å fortsette.')}
                        fullWidth={true}
                        autoFocus={true}
                        disabled={false}
                    />
                    <FormHelperText error={lastAttemptFailed}>
                        {lastAttemptFailed ? t('wrongCode', 'Vi klarte ikke å autentisere deg. Verifiser at engangskoden er riktig.'): ''}
                    </FormHelperText>
                    <FormSubmit
                        variant={"contained"}
                        startIcon={<LoginIcon/>}
                        sx={{
                            mt: 2,
                            width: "100%"
                        }}
                    >
                        {t('loginButton', 'Logg inn')}
                    </FormSubmit>
                </Form>
            </FormProvider>
        </Box>
    )
}