import {Form} from "../../form/Form";
import {TextFieldForm} from "../../form/TextFieldForm";
import React, {useEffect, useState} from "react";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useSelector} from "react-redux";
import {FORM_VALUE_UPDATED, useFormDispatch} from "../../form/formContext";

export const CodeAndPasswordForm = ({email, disabled, t}) => {
    const forgotPasswordState = useSelector(state => state.forgotPassword)
    const formDispatch = useFormDispatch()
    const [showCode, setShowCode] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showRepeatPassword, setShowRepeatPassword] = useState(false)

    useEffect(() => {
        if(forgotPasswordState.failed) {
            formDispatch({
                type: FORM_VALUE_UPDATED,
                key: 'code',
                value: ''
            })
        }
    }, [forgotPasswordState.failed, formDispatch])

    return (
        <Form
            disabled={disabled}
        >
            <input
                hidden={true}
                type={'email'}
                autoComplete={'username'}
                value={email}
                readOnly={true}
            />
            <TextFieldForm
                sx={{
                    mt: 2
                }}
                formKey={'code'}
                label={t('codeTitle', 'Kode', {ns: 'login.passwordForm'})}
                ariaLabel={t('codeHelperText', 'Skriv inn engangskoden du har motatt.', {ns: 'login.passwordForm'})}
                fullWidth={true}
                autoFocus={true}
                type={showCode ? 'text' : 'password'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowCode(!showCode)}
                                onMouseDown={e => e.preventDefault()}
                                tabIndex={-1}
                                size="large">
                                {showCode ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                disabled={disabled}
            />
            <TextFieldForm
                sx={{
                    mt: 2
                }}
                formKey={'newPassword'}
                label={t('title', 'Nytt passord', {ns: 'login.passwordForm'})}
                ariaLabel={t('helperText', 'Skriv inn et ønsket passord', {ns: 'login.passwordForm'})}
                fullWidth={true}
                type={showNewPassword ? 'text' : 'password'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowNewPassword(!showNewPassword)}
                                onMouseDown={e => e.preventDefault()}
                                tabIndex={-1}
                                size="large">
                                {showNewPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                disabled={disabled}
            />
            <TextFieldForm
                sx={{
                    mt: 2
                }}
                formKey={'repeatPassword'}
                label={t('repeatTitle', 'Gjenta nytt passord', {ns: 'login.passwordForm'})}
                ariaLabel={t('repeatHelperText', 'Gjenta nøyaktig det samme passordet', {ns: 'login.passwordForm'})}
                fullWidth={true}
                type={showRepeatPassword ? 'text' : 'password'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                                onMouseDown={e => e.preventDefault()}
                                tabIndex={-1}
                                size="large">
                                {showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                disabled={disabled}
            />
        </Form>
    )
}