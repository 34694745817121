import {useDeepCompareEffectNoCheck} from "use-deep-compare-effect";
import {FORM_VALUE_ERROR, FORM_VALUE_NO_ERROR, useFormDispatch, useFormState} from "./formContext";

export const useFieldValidation = formKey => {
    const {
        formData,
        schema
    } = useFormState()
    const dispatch = useFormDispatch()

    useDeepCompareEffectNoCheck(() => {
        schema.validateAt(formKey, formData)
            .then(() => dispatch({type: FORM_VALUE_NO_ERROR, key: formKey}))
            .catch(exception => {
                dispatch({type: FORM_VALUE_ERROR, key: formKey, exception: exception})
            })
    }, [formData[formKey], schema])
}