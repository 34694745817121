import {Typography} from "@mui/material";
import Link from "@mui/material/Link";
import {Link as RouteLink, useNavigate} from "react-router-dom";
import React, {Fragment, useEffect} from "react";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import {getUserInfo} from "../login/authsAction";
import {useLoggedIn} from "../hooks/useLoggedIn";
import {ProgressIndicator} from "../app/ProgressIndicator";
import {useLoginState, useLoginTranslation} from "../login/loginContext";

export const Welcome = () => {
    const t = useLoginTranslation()
    const authsState = useSelector(state => state.auths);
    const {
        redirectUri
    } = useLoginState()
    const dispatch = useDispatch();
    const navigate = useNavigate()

    useLoggedIn(redirectUri || window._env_.REACT_APP_DEFAULT_REDIRECT_URI);

    useEffect(() => {
        if(authsState.loggedIn) {
            dispatch(getUserInfo(authsState.accessToken))
        }
    }, [authsState.loggedIn, dispatch, authsState.accessToken]);


    if(!authsState.inProgress && authsState.failed) {
        navigate('/')
    }

    if(!authsState.name) {
        return (
            <Box>
                <ProgressIndicator />
            </Box>
        )
    }

    return <Fragment>
        <Typography color='textPrimary'>
            {t('welcome', 'Velkommen {{name}}', {name: authsState.name})}
        </Typography>
        <Typography>
            <Link
                href={window._env_.REACT_APP_DASHBOARD_URL}
                sx={{
                    margin: 1
                }}
                underline="hover">
                {t('linkToDashboard', 'Ta meg til dashboardet')}
            </Link>
        </Typography>
        <Typography>
            <Link
                to='/oauth2/logout'
                sx={{
                    margin: 1
                }}
                component={RouteLink}
                underline="hover">
                {t('logoutLink', 'Logg ut')}
            </Link>
        </Typography>
    </Fragment>;
};