import {DOMAIN_CLEARED, DOMAIN_SET, useSamlDispatch, useSamlState} from "./samlContext";
import useDeepCompareEffect from "use-deep-compare-effect";

const idpHandlesEmailDomain = (email, idp) => {
    const emailLowerCase = email.toLowerCase()
    const domains = idp.additionalDomains.map(ad => ad.additionalDomain)
    console.debug({domains})
    return domains.find(domain => emailLowerCase.endsWith(`@${domain}`))
}


export const useSAMLIdp = ({email, valid}) => {
    const samlDispatch = useSamlDispatch()
    const {idps} = useSamlState()

    useDeepCompareEffect(
        () => {
            if(valid && idps.find(idp => idpHandlesEmailDomain(email, idp))) {
                const idp = idps.find(idp => idpHandlesEmailDomain(email, idp))
                console.debug({idp})
                samlDispatch({
                    type: DOMAIN_SET,
                    domain: idp.domain,
                    specificDomain: idpHandlesEmailDomain(email, idp)
                })
            } else {
                samlDispatch({
                    type: DOMAIN_CLEARED
                })
            }
        },
        [email, valid, samlDispatch, idps]
    )
}