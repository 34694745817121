import {
    CONFIRM_SIGN_UP_FAILED,
    CONFIRM_SIGN_UP_FINISHED,
    CONFIRM_SIGN_UP_STARTED,
    RESEND_CONFIRMATION_CODE_FAILED,
    RESEND_CONFIRMATION_CODE_FINISHED,
    RESEND_CONFIRMATION_CODE_STARTED, SIGN_UP_DIALOG_STARTED,
    SIGN_UP_FAILED,
    SIGN_UP_FINISHED,
    SIGN_UP_STARTED,
    SIGN_UP_STEP_CHANGED
} from "./signUpAction";

const initialState = {
    inProgress: false,
    failed: false,
    step: 'userdata',
    email: ''
};

export const signUp = (state=initialState, action) => {
    switch(action.type) {
        case SIGN_UP_DIALOG_STARTED:
            return initialState;
        case SIGN_UP_STARTED:
            return {
                ...state,
                inProgress: true,
                failed: false
            };
        case SIGN_UP_FINISHED:
            return {
                ...state,
                inProgress: false
            };
        case SIGN_UP_FAILED:
            return {
                ...state,
                inProgress: false,
                failed: true,
                email: action.email
            };
        case CONFIRM_SIGN_UP_STARTED:
            return {
                ...state,
                inProgress: true,
                failed: false
            };
        case CONFIRM_SIGN_UP_FINISHED:
            return {
                ...state,
                inProgress: false,
                step: 'success'
            };
        case CONFIRM_SIGN_UP_FAILED:
            return {
                ...state,
                inProgress: false,
                failed: true
            };
        case RESEND_CONFIRMATION_CODE_STARTED:
            return {
                ...state,
                inProgress: true,
                failed: false
            };
        case RESEND_CONFIRMATION_CODE_FINISHED:
            return {
                ...state,
                inProgress: false,
            };
        case RESEND_CONFIRMATION_CODE_FAILED:
            return {
                ...state,
                inProgress: false,
                failed: true
            };
        case SIGN_UP_STEP_CHANGED:
            return {
                ...state,
                step: action.step,
                email: action.email
            };
        default:
            return state
    }
};