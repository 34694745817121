import React from 'react'

const SamlStateContext = React.createContext(undefined)
const SamlDispatchContext = React.createContext(undefined)

export const DOMAIN_SET = 'DOMAIN_SET'
export const DOMAIN_CLEARED = 'DOMAIN_CLEARED'
export const IDPS_SET = 'IDPS_SET'

const initialState = {
    domain: null,
    specificDomain: null,
    idps: []
}

const samlReducer = (state, action) => {
    console.debug({action})
    switch (action.type) {
        case DOMAIN_SET:
            return {
                ...state,
                domain: action.domain,
                specificDomain: action.specificDomain
            }
        case DOMAIN_CLEARED:
            return {
                ...state,
                domain: null,
                specificDomain: null,
            }
        case IDPS_SET:
            return {
                ...state,
                idps: action.idps
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}

export const SamlProvider = ({children}) => {
    const [state, dispatch] = React.useReducer(samlReducer, initialState, undefined)

    return (
        <SamlStateContext.Provider value={state}>
            <SamlDispatchContext.Provider value={dispatch}>
                {children}
            </SamlDispatchContext.Provider>
        </SamlStateContext.Provider>
    )
}

export const useSamlState = () => {
    const context = React.useContext(SamlStateContext)
    if (context === undefined) {
        throw new Error('useSamlState must be used within a SamlProvider')
    } else {
        return context
    }
}

export const useSamlDispatch = () => {
    const context = React.useContext(SamlDispatchContext)
    if (context === undefined) {
        throw new Error('useSamlDispatch must be used within a SamlProvider')
    } else {
        return context
    }
}
