import React from 'react';
import {ForgotPasswordDialog} from "../login/reset-password/ForgotPasswordDialog";
import {Signup} from "../signup/Signup";
import {LoginOptions} from "../login/LoginOptions";
import {Route, Routes} from "react-router-dom";
import {Logout} from "../logout/Logout";
import {Welcome} from "../authenticated/Welcome";
import {Callback} from "../authenticated/Callback";
import {ResetRequired} from "../password/ResetRequired";
import {MFA} from "../login/MFA";

export const AppRoutes = () => {

    return (
        <Routes>
            <Route
                path='/forgot-password/:initialEmail'
                element={<ForgotPasswordDialog/>}
            />

            <Route
                path='/forgot-password'
                element={<ForgotPasswordDialog/>}
            />
            <Route
                path='/signup'
                element={<Signup/>}
            />

            <Route
                path='/confirm/:email'
                element={<Signup/>}
            />

            <Route
                path='/welcome'
                element={<Welcome/>}
            />
            <Route
                path={"/reset-required"}
                element={<ResetRequired/>}
            />

            <Route
                path='/logout'
                element={<Logout/>}
            />
            <Route
                path='/oauth2/logout'
                element={<Logout/>}
            />

            <Route
                path='/oauth2/authorize'
                element={<LoginOptions/>}
            />
            <Route
                path='/oauth2/callback'
                element={<Callback/>}
            />
            <Route
                path={"/mfa"}
                element={<MFA/>}
            />
            <Route
                path='/'
                element={<LoginOptions/>}
            />
        </Routes>
    )
};