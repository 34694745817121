import React, {Fragment} from 'react';
import {styled} from '@mui/material/styles';
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {useAppLoginLogo} from "./useAppLoginLogo";
import {Stack} from "@mui/material";
import {useSyncQueryParams} from "../login/useSyncQueryParams";
import {useLoginState} from "../login/loginContext";
import {ProgressIndicator} from "./ProgressIndicator";

const PREFIX = 'LayoutContainer';

const classes = {
    grid: `${PREFIX}-grid`,
    logo: `${PREFIX}-logo`,
    loginBox: `${PREFIX}-loginBox`,
    smallLogo: `${PREFIX}-smallLogo`
};

const StyledGrid = styled(Grid)(() => ({
    [`&.${classes.grid}`]: {
        flexGrow: 1,
        height: '90vh'
    },

    [`& .${classes.logo}`]: {
        width: '60%'
    },

    [`& .${classes.loginBox}`]: {
        backgroundColor: 'white',
        padding: '50px 20px 50px 20px',
        borderRadius: '20px',
        border: '#4267b2 thin',
        boxShadow: '1px 1px 4px 0 rgba(0,0,0,.15)'
    },

    [`& .${classes.smallLogo}`]: {
        width: '20%'
    }
}));

export const LayoutContainer = ({children}) => {

    const appLoginLogo = useAppLoginLogo();
    useSyncQueryParams()
    const {
        redirectUri
    } = useLoginState()


    const renderLogo = () => {
        if(appLoginLogo) {
            return (
                <Fragment>
                    <Box>
                        <img src={`https://dms-cf-01.dimu.org/image/${appLoginLogo}?mediaType=image/svg`} alt='ekultur logo' className={classes.logo}/>
                    </Box>
                    <Box>
                        <img src='/ekultur.png' alt='ekultur logo' className={classes.smallLogo}/>
                    </Box>
                </Fragment>
            )
        } else {
            return <img src='/ekultur.png' alt='ekultur logo' className={classes.logo}/>
        }
    };

    if(!redirectUri) {
        return (
            <Stack
                alignItems={'center'}
                justifyContent={'center'}
            >
                <ProgressIndicator />
            </Stack>
        )
    }

    return (
        <StyledGrid className={classes.grid} container justifyContent='center' alignItems='center'>
            <Container maxWidth={'xs'}>
                <Box className={classes.loginBox}>
                    <Stack
                        alignItems={"center"}
                    >
                        {renderLogo()}
                        {children}
                    </Stack>
                </Box>
            </Container>
        </StyledGrid>
    );
};