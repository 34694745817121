import React, {useEffect, useState} from 'react';
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import {Link as RouteLink} from "react-router-dom";
import queryString from "query-string";
import {Stack} from "@mui/material";
import {useLoginState, useLoginTranslation} from "../../loginContext";

export const SuccessStep = () => {
    const [params, setParams] = useState({});
    const {
        responseType,
        clientId,
        redirectUri,
        state
    } = useLoginState()
    const t = useLoginTranslation()

    useEffect(() => {
        setParams({
            response_type: responseType,
            client_id: clientId,
            redirect_uri: redirectUri,
            state: state
        })
    }, [responseType, clientId, redirectUri, state]);

    return (
        <Stack
            spacing={2}
            alignItems={"stretch"}
            sx={{
                width: "100%",
                mt: 2
            }}
        >
            <Typography variant={'h4'}>
                {t(
                    'successTitle',
                    'Passord endret',
                    {
                        ns: 'login.resetPassword'
                    }
                )}
            </Typography>
            <Typography>
                {t(
                    'successBody',
                    'Vi har gleden av å meddele at passordet ditt er blitt oppdatert etter ditt  ønske.',
                    {
                        ns: 'login.resetPassword'
                    }
                )}
            </Typography>
            <Typography>
                <Link
                    to={`/oauth2/authorize/?${queryString.stringify(params)}`}
                    component={RouteLink}
                    underline="hover">
                    {t(
                        'successLink',
                        'Ta meg til innloggingen',
                        {
                            ns: 'login.resetPassword'
                        }
                    )}
                </Link>
            </Typography>
        </Stack>
    )
}