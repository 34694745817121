import {useEffect} from "react";
import {IDPS_SET, useSamlDispatch} from "./samlContext";

export const useIdps = () => {
    const samlDispatch = useSamlDispatch()

    useEffect(() => {
        fetch(`${window._env_.REACT_APP_APIGATEWAY}/authz/identity-providers/`)
            .then(response => response.json())
            .then(json => {
                samlDispatch({
                    type: IDPS_SET,
                    idps: json.filter(idp => !idp.disabled)
                })
            })
    }, [samlDispatch]);
}