import {MFAContent} from "./MFAContent";
import {MFAForm} from "./MFAForm";

export const MFA = () => {
    return (
        <>
            <MFAContent />
            <MFAForm />
        </>
    )
}