import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {LayoutContainer} from "./LayoutContainer";
import {AppRoutes} from "./AppRoutes";
import {Theme} from "./Theme";
import {LoginProvider} from "../login/loginContext";

const App = () => {
    if(!window?._env_.REACT_APP_APIGATEWAY) {
        return null;
    }

    return (
        <Theme>
            <Router>
                <LoginProvider>
                    <LayoutContainer>
                        <AppRoutes/>
                    </LayoutContainer>
                </LoginProvider>
            </Router>
        </Theme>
    );
};

export default App;

