import {TextFieldForm} from "../form/TextFieldForm";
import React from "react";
import {Form} from "../form/Form";
import {Stack} from "@mui/material";
import {FormSubmit} from "../form/FormSubmit";
import LoginIcon from "@mui/icons-material/Login";
import {useSAMLIdp} from "./useSAMLIdp";
import {useFormState} from "../form/formContext";
import {ProgressIndicator} from "../app/ProgressIndicator";
import {useSamlState} from "./samlContext";

export const UsernameForm = ({disabled=false, t}) => {
    const {
        formData,
        errors,
        initialized
    } = useFormState()

    const {specificDomain} = useSamlState()

    useSAMLIdp({
        email: formData.username,
        valid: Boolean(formData.username !== undefined && !errors.username)
    })

    if(!initialized) {
        return (
            <Stack
                alignItems={'center'}
                justifyContent={'center'}
            >
                <ProgressIndicator />
            </Stack>
        )
    }

    return (
        <Form
            disabled={disabled}
            forceEnable={Boolean(formData.samlDomain)}
        >
            <TextFieldForm
                formKey={'username'}
                label={t('email', 'E-post')}
                ariaLabel={t('emailHelperText', 'Skriv inn e-posten din')}
                fullWidth={true}
                autoFocus={true}
                disabled={disabled}
            />
            <FormSubmit
                forceEnable={true}
                variant={"contained"}
                startIcon={<LoginIcon/>}
                sx={{
                    mt: 2,
                    width: "100%"
                }}
            >
                {t('samlLoginButton', 'Logg inn på {{provider}}', {provider: specificDomain})}
            </FormSubmit>
        </Form>
    )
}