import React from 'react';
import Fade from "@mui/material/Fade";
import {CircularProgress} from "@mui/material";

export const ProgressIndicator = () => {

    return (
        <div>
            <Fade
                in={true}
                style={{
                    transitionDelay: '800ms'
                }}
                unmountOnExit={true}
            >
                <CircularProgress />
            </Fade>
        </div>
    )
};