import {useEffect} from "react";
import {FORM_INITIALIZED, useFormDispatch} from "../../form/formContext";
import {useQueryParams} from "../../app/useQueryParams";

export const InitializeForm = () => {
    const [queryParams, setQueryParams] = useQueryParams()
    const formDispatch = useFormDispatch()


    useEffect(
        () => {
            const email = queryParams.get('email')
            if(null !== email) {
                formDispatch({
                    type: FORM_INITIALIZED,
                    initialFormData: {
                        username: email
                    }
                })
            } else {
                formDispatch({
                    type: FORM_INITIALIZED,
                    initialFormData: {
                    }
                })
            }
        },
        [queryParams.get('email')]
    )
    return null
}