import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import {Link as RouteLink} from "react-router-dom";
import {Stack} from "@mui/material";
import React from "react";
import {useFormState} from "../../form/formContext";

export const HelperLinks = ({t}) => {
    const {formData} = useFormState()
    return (
        <Stack
            alignItems={"flex-end"}
            sx={{
                mt: 2,
                mb: 1
            }}
        >
            <Typography>
                <Link
                    to={`/forgot-password/${formData.username || ''}`}
                    component={RouteLink}
                    underline="hover">
                    {t('forgotPassword', 'Glemt passordet?')}
                </Link>
            </Typography>
            <Typography>
                <Link
                    to='/signup'
                    sx={{
                        margin: 1
                    }}
                    component={RouteLink}
                    underline="hover">
                    {t('newUser', 'Ny her? Opprett en konto')}
                </Link>
            </Typography>
        </Stack>
    )
}