import React from 'react';
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {forgotPassword} from "../../forgotPasswordAction";
import {Stack} from "@mui/material";
import * as Yup from "yup";
import {FormProvider} from "../../../form/formContext";
import {UsernameForm} from "../UsernameForm";
import {FormSubmit} from "../../../form/FormSubmit";
import {Send} from "@mui/icons-material";
import FormHelperText from "@mui/material/FormHelperText";
import {SamlProvider} from "../../samlContext";
import {WithSamlIdp} from "../../WithSamldp";
import {InitializeForm} from "../../username-password/InitializeForm";
import {useSamlLogin} from "../../useSamlLogin";
import {useLoginTranslation} from "../../loginContext";

export const EnterEmailStep = () => {
    const dispatch = useDispatch();
    const forgotPasswordState = useSelector(state => state.forgotPassword)
    const t = useLoginTranslation()
    const samlLogin = useSamlLogin()

    const validationSchema = Yup.object().shape({
        username: Yup.string()
            .email(t('validEmail', 'Må være en gyldig epostadresse'))
            .required(t('emailHelperText', 'Skriv inn e-posten din')),
    })

    const submitHandler = formData => {
        if(formData.samlDomain) {
            samlLogin(formData.samlDomain)
        } else {
            dispatch(forgotPassword(formData.username.toLowerCase()))
        }
    }

    return (
        <FormProvider
            schema={validationSchema}
            onSubmit={submitHandler}
        >
            <InitializeForm />
            <SamlProvider>
                <WithSamlIdp>
                    <Stack
                        spacing={2}
                        alignItems={"stretch"}
                        sx={{
                            width: "100%",
                            mt: 2
                        }}
                    >
                        <Typography variant={'h4'}>
                            {t('login.resetPassword:title', 'Glemt passordet ditt?')}
                        </Typography>
                        <Typography>
                            {t('login.resetPassword:body', 'Skriv inn epostadressen din under så sender vi deg en epost med' +
                                ' instrukser for å tilbakestille passordet.')}
                        </Typography>
                        <UsernameForm
                            t={t}
                            disabled={false}
                        />
                        <FormHelperText
                            error={forgotPasswordState.failed}
                        >
                            {forgotPasswordState.failed ?
                                t('login.resetPassword:error', 'Vi klarte ikke å hjelpe deg med å tilbakestille passordet ditt.' +
                                ' Sjekk om eposten er riktig.'): ''}
                        </FormHelperText>
                        <FormSubmit
                            loadingButton={true}
                            loading={forgotPasswordState.inProgress}
                            loadingPosition={"start"}
                            disabled={forgotPasswordState.inProgress}
                            variant={"contained"}
                            startIcon={<Send />}
                            sx={{
                                mt: 2
                            }}
                        >
                            {t('login.resetPassword:button', 'Tilbakestill passordet mitt')}
                        </FormSubmit>
                    </Stack>
                </WithSamlIdp>
            </SamlProvider>
        </FormProvider>
    )
};