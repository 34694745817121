import {useEffect, useState} from "react";
import {useLoginState} from "../login/loginContext";

export const useAppLoginLogo = () => {
    const {redirectUri} = useLoginState()
    const [icon, setIcon] = useState('');

    useEffect(() => {
        if(redirectUri && !redirectUri.startsWith("http://localhost")) {
            fetch(`${window._env_.REACT_APP_APIGATEWAY}/app-registry/apps/?url=${redirectUri}`)
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        return Promise.reject()
                    }
                })
                .then(json => {
                     setIcon(json?.login_logo || '')
                })
                .catch(() => console.error('Could not find app'))

        }
    }, [redirectUri, setIcon]);

    return icon
};