import React, {useEffect, useState} from 'react';
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import {Link as RouteLink} from "react-router-dom";
import queryString from "query-string";
import {Stack} from "@mui/material";
import {useLoginState, useLoginTranslation} from "../../login/loginContext";

export const SuccessStep = () => {
    const t = useLoginTranslation()
    const [params, setParams] = useState({});
    const {
        responseType,
        clientId,
        redirectUri,
        state
    } = useLoginState()

    useEffect(() => {
        setParams({
            response_type: responseType,
            client_id: clientId,
            redirect_uri: redirectUri,
            state: state
        })
    }, [responseType, clientId, redirectUri, state]);

    return (
        <Stack
            spacing={2}
            alignItems={"stretch"}
            sx={{
                width: "100%",
                mt: 2
            }}
        >
            <Typography variant='subtitle1' color='textPrimary'>
                {t(
                    'accountCreated',
                    'Kontoen din er opprettet og verifisert.',
                    {
                        ns: 'login.newUser'
                    }
                )}
            </Typography>
            <Typography>
                <Link
                    to={`/oauth2/authorize/?${queryString.stringify(params)}`}
                    component={RouteLink}
                    underline="hover">
                    {t(
                        'toLoginLink',
                        'Ta meg til inlogging',
                        {
                            ns: 'login.newUser'
                        }
                    )}
                </Link>
            </Typography>
        </Stack>
    )
}