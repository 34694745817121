import React from 'react';
import {Button} from "@mui/material";
import {FORM_SUBMITTED, useFormDispatch, useFormState} from "./formContext";
import {isEqual} from "lodash";
import LoadingButton from '@mui/lab/LoadingButton';

export const FormSubmit = ({disabled=false, forceEnable=false, loadingButton=false, children, ...props}) => {
    const {isSubmitting, errors, formData, initialFormData} = useFormState()
    const dispatch = useFormDispatch()

    const isModified = !isEqual(formData, initialFormData)

    const submitForm = () => dispatch({type: FORM_SUBMITTED})

    const disabledButton = () => {
        if (forceEnable) {
            return false
        } else {
            return isSubmitting || 0 < Object.keys(errors).length || disabled || !isModified
        }
    }

    if(loadingButton) {
        return (
            <LoadingButton
                type="submit"
                onClick={submitForm}
                disabled={disabledButton()}
                {...props}
            >
                {children}
            </LoadingButton>
        )
    } else {
        return (
            <Button
                type="submit"
                onClick={submitForm}
                disabled={disabledButton()}
                {...props}
            >
                {children}
            </Button>
        )
    }


}
