import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {forgotPassword} from "../login/forgotPasswordAction";

export const SendCode = ({email}) => {
    const dispatch = useDispatch()

    useEffect(
        () => {
            if(null !== email) {
                dispatch(forgotPassword(email))
            }
        },
        [email, dispatch]
    )
    return null
}