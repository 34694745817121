import React, {useState} from "react";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {Form} from "../form/Form";
import {TextFieldForm} from "../form/TextFieldForm";

export const CodeForm = ({disabled, t}) => {
    const [showCode, setShowCode] = useState(false)
    return (
        <Form
            disabled={disabled}
        >
            <TextFieldForm
                sx={{
                    mt: 2
                }}
                formKey={'code'}
                label={t('codeTitle', 'Kode', {ns: 'login.passwordForm'})}
                ariaLabel={t('codeHelperText', 'Skriv inn engangskoden du har motatt.', {ns: 'login.passwordForm'})}
                fullWidth={true}
                autoFocus={true}
                type={showCode ? 'text' : 'password'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowCode(!showCode)}
                                onMouseDown={e => e.preventDefault()}
                                tabIndex={-1}
                                size="large">
                                {showCode ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                disabled={disabled}
            />
        </Form>
    )
}