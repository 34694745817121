import React from 'react';
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import {Link as RouteLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {confirmRegistration} from "../signUpAction";
import * as Yup from "yup";
import {FormProvider} from "../../form/formContext";
import {Stack} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import {FormSubmit} from "../../form/FormSubmit";
import {VerifiedUser} from "@mui/icons-material";
import {CodeForm} from "../CodeForm";
import {useLoginTranslation} from "../../login/loginContext";

export const CodeVerificationStep = () => {
    const t = useLoginTranslation()
    const dispatch = useDispatch();
    const signUpState = useSelector(state => state.signUp);

    const validationSchema = Yup.object().shape({
        code: Yup.string()
            .required(
                t('codeHelperText', 'Skriv inn engangskoden du har motatt.', {ns: 'login.passwordForm'})
            ),
    })

    const submitHandler = formData => {
        dispatch(confirmRegistration({
            email: signUpState.email,
            verificationCode: formData.code
        }));
    }

    return (
        <FormProvider
            schema={validationSchema}
            onSubmit={submitHandler}
        >
            <Stack
                spacing={2}
                alignItems={"stretch"}
                sx={{
                    width: "100%",
                    mt: 2
                }}
            >
                <Typography variant='subtitle1' color='textPrimary'>
                    {t(
                        'verifyEmailTitle',
                        'Verifiser epost',
                        {
                            ns: 'login.newUser'
                        }
                    )}
                </Typography>

                {t(
                    'verifyEmailSubtitle',
                    'Vi har sendt deg en kode på epost til {{email}}. Skriv inn denne koden under for å verifisere epostadressen. Være obs på at spam-filteret kan fange opp eposten.',
                    {
                        ns: 'login.newUser',
                        email: signUpState.email
                    }
                )}

                <CodeForm
                    t={t}
                    disabled={false}
                />
                <FormHelperText
                    error={signUpState.failed}
                >
                    {signUpState.failed ?
                        t(
                            'verifyAccountHelperText',
                            'Vi klarte ikke å verifisere deg. Har du skrevet koden riktig?',
                            {
                                ns: 'login.newUser'
                            }
                        ) : ''
                    }
                </FormHelperText>
                <FormSubmit
                    loadingButton={true}
                    loading={signUpState.inProgress}
                    loadingPosition={"start"}
                    disabled={signUpState.inProgress}
                    variant={"contained"}
                    startIcon={<VerifiedUser />}
                    sx={{
                        mt: 2
                    }}
                >
                    {t(
                        'verifyAccountButton',
                        'Bekreft kontoen',
                        {
                            ns: 'login.newUser'
                        }
                    )}
                </FormSubmit>
                <Stack
                    direction={"row"}
                    alignItems={"flex-start"}
                    justifyContent={'flex-end'}
                >
                    <Typography>
                        <Link
                            to={`/confirm/${signUpState.email}`}
                            sx={{
                                margin: 1
                            }}
                            component={RouteLink}
                            underline="hover">
                            {t(
                                'resendCodeLink',
                                'Send meg en ny kode',
                                {
                                    ns: 'login.newUser'
                                }
                            )}
                        </Link>
                    </Typography>
                </Stack>
            </Stack>
        </FormProvider>
    )
}