import {Accordion, AccordionDetails, AccordionSummary, Stack} from "@mui/material";
import {ErrorOutline, ExpandMore} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import {useLoginTranslation} from "./loginContext";
import {Trans} from "react-i18next";
import Link from "@mui/material/Link";

export const InfoMessage = () => {
    const t = useLoginTranslation()
    return (
        <Accordion
            sx={{
                backgroundColor: "rgba(100, 182, 247, 0.12)",
                border: "1px solid #DDE8EE",
                borderRadius: "5px"
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMore />}
                id={"info-message-header"}
                arian-controls={"info-message-content"}
            >
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={1}
                >
                    <ErrorOutline
                        sx={{
                            color: "#666"
                        }}
                    />
                    <Typography
                        variant={"body2"}
                        sx={{
                            fontSize: "12px",
                            color: "#666",
                            fontWeight: 500
                        }}
                    >
                        {t('googleAndFacebookDisabledSummary', "Google/Facebook-innlogging støttes ikke lenger")}
                    </Typography>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Typography
                    variant={"body2"}
                    sx={{
                        fontSize: "12px",
                        color: "#666"
                    }}
                >
                    <Trans
                        t={t}
                        i18nKey={"googleAndFacebookDisabledDetails"}
                    >
                        Hvis du tidligere har logget inn via Google eller Facebook kan du <Link href={"/forgot-password/"}>sette nytt passord</Link> med e-postadressen du bruker hos Google/Facebook. Ditt eksisterende eKultur-innhold blir tilgjengelig..
                    </Trans>
                </Typography>
            </AccordionDetails>
        </Accordion>
    )
}