import React from 'react';
import {FORM_SUBMITTED, useFormDispatch, useFormState} from "./formContext";
import {isEqual} from "lodash";

export const Form = ({disabled=false, forceEnable=false, children}) => {
    const {isSubmitting, errors, formData, initialFormData} = useFormState()
    const dispatch = useFormDispatch()

    const isModified = !isEqual(formData, initialFormData)

    const submitForm = () => dispatch({type: FORM_SUBMITTED})

    const submitDisabled = () => {
        if(forceEnable) {
            return false
        } else {
            return isSubmitting || 0 < Object.keys(errors).length || disabled || !isModified
        }
    }

    return (
        <form
            onSubmit={e => {
                e.preventDefault()
                if(submitDisabled()) {
                    return
                }
                submitForm()
            }}
        >
            {children}
            <input type="submit" hidden />
        </form>

    )
}
