import {useSelector} from "react-redux";
import React, {Fragment} from "react";
import {useQueryParams} from "../app/useQueryParams";
import {SendCode} from "./SendCode";
import {ResetPasswordStep} from "./ResetPasswordStep";
import {SuccessStep} from "../login/reset-password/steps/SuccessStep";

export const ResetRequired = () => {
    const forgotPasswordState = useSelector(state => state.forgotPassword);
    const [queryParams, setQueryParams] = useQueryParams()

    const email = queryParams.get('email');

    const step = {
        'codeAndPassword': <ResetPasswordStep email={email}/>,
        'success': <SuccessStep />
    }

    if(!email) {
        return null
    } else {
        return (
            <Fragment>
                <SendCode email={email} />
                {step[forgotPasswordState.step]}
            </Fragment>
        )
    }
}