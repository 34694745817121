import {Form} from "../../form/Form";
import {TextFieldForm} from "../../form/TextFieldForm";
import React from "react";

export const UsernameForm = ({disabled, t}) => {
    return (
        <Form
            disabled={disabled}
        >
            <TextFieldForm
                formKey={'username'}
                label={t('email', 'E-post')}
                ariaLabel={t('emailHelperText', 'Skriv inn e-posten din')}
                fullWidth={true}
                autoFocus={true}
                disabled={disabled}
                sx={{
                    '& input': {
                        textTransform: 'lowercase'
                    }
                }}
            />
        </Form>
    )
}