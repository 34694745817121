export const FORGOT_PASSWORD_DIALOG_STARTED = 'forgotPassword/dialogStarted';
export const FORGOT_PASSWORD_EMAIL_CHANGED = 'forgotPassword/emailChanged';
export const FORGOT_PASSWORD_STARTED = 'forgotPassword/started';
export const FORGOT_PASSWORD_FINISHED = 'forgotPassword/finished';
export const FORGOT_PASSWORD_FAILED = 'forgotPassword/failed';
export const CONFIRM_PASSWORD_STARTED = 'forgotPassword/confirmStarted';
export const CONFIRM_PASSWORD_FINISHED = 'forgotPassword/confirmFinished';
export const CONFIRM_PASSWORD_FAILED = 'forgotPassword/confirmFailed';

export const setEmail = email => dispatch => {
    dispatch({
        type: FORGOT_PASSWORD_EMAIL_CHANGED,
        email: email
    })
};

export const startForgotPasswordGuide = () => dispatch => {
    dispatch({type: FORGOT_PASSWORD_DIALOG_STARTED})
};

export const forgotPassword = email => dispatch => {
    dispatch({type: FORGOT_PASSWORD_STARTED});

    const data = {
        username: email
    };

    userpoolFetch({operation: 'forgot-password', data: data})
        .then(() => dispatch({
            type: FORGOT_PASSWORD_FINISHED,
            email: email,
            step: 'codeAndPassword'
        }))
        .catch(() => dispatch({type: FORGOT_PASSWORD_FAILED}))
};

export const confirmPassword = ({email, verificationCode, newPassword}) => dispatch => {
    dispatch({type: CONFIRM_PASSWORD_STARTED});

    const data = {
        username: email,
        verificationCode: verificationCode,
        password: newPassword,
    };

    userpoolFetch({operation: 'confirm-password', data: data})
        .then(() => dispatch({
            type: CONFIRM_PASSWORD_FINISHED,
            step: 'success'
        }))
        .catch(() => dispatch({type: CONFIRM_PASSWORD_FAILED}))
};

const userpoolFetch = ({operation, data}) => {
    return fetch(`${window._env_.REACT_APP_APIGATEWAY}/auths/userpool/${operation}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
        .then(response => response.ok ? Promise.resolve() : Promise.reject())
};