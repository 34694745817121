import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useLoginTranslation} from "./loginContext";

export const MFAContent = () => {
    const t = useLoginTranslation()
    return (
        <Box>
            <Typography variant='subtitle1' color='textPrimary' textAlign={"center"}>
                Tofaktorautentisering
            </Typography>
        </Box>
    )
}