import {TextField} from "@mui/material";
import React from "react";
import {FORM_VALUE_UPDATED, useFormDispatch, useFormState} from "./formContext";
import {useFieldValidation} from "./useFieldValidation";
import {useIsFieldRequired} from "./useIsFieldRequired";

export const TextFieldForm = ({formKey, label, ariaLabel, fullWidth=false, multiline=false, onBlur=null, ...rest}) => {
    const {
        formData,
        errors
    } = useFormState()

    const dispatch = useFormDispatch()
    useFieldValidation(formKey)
    const required = useIsFieldRequired(formKey)

    const changeHandler = event => {
        dispatch({
            type: FORM_VALUE_UPDATED,
            key: formKey,
            value: event.target.value
        })
    }

    const error = formData[formKey] === undefined ? null : errors[formKey]

    return (
        <TextField
            id={formKey}
            name={label}
            label={label}
            helperText={error ? error : ariaLabel}
            error={Boolean(error)}
            value={formData[formKey]}
            onChange={changeHandler}
            required={required}
            multiline={multiline}
            fullWidth={fullWidth}
            onBlur={onBlur}
            {...rest}
        />
    );
}