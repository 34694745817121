import {
    AUTHS_GET_USER_INFO_FINISHED, AUTHS_TOKEN_FROM_CODE_FAILED,
    AUTHS_TOKEN_FROM_CODE_FINISHED, AUTHS_TOKEN_FROM_CODE_STARTED,
    AUTHS_TOKEN_REFRESH_FAILED, AUTHS_TOKEN_REFRESH_STARTED,
    AUTHS_TOKEN_REFRESHED
} from "./authsAction";

const initialState = {
    loggedIn: false,
    accessToken: '',
    expiresIn: 0,
    name: '',
    failed: false,
    inProgress: false,
    codeToTokenFailed: false
};

export const auths = (state=initialState, action) => {
    switch(action.type) {
        case AUTHS_TOKEN_REFRESH_STARTED:
            return {
                ...state,
                inProgress: true
            };
        case AUTHS_TOKEN_REFRESHED:
            return {
                ...state,
                loggedIn: true,
                accessToken: action.accessToken,
                expiresIn: action.expiresIn,
                failed: false,
                inProgress: false
            };
        case AUTHS_TOKEN_REFRESH_FAILED:
            return {
                ...state,
                loggedIn: false,
                accessToken: '',
                expiresIn: 0,
                name: '',
                failed: true,
                inProgress: false
            };
        case AUTHS_GET_USER_INFO_FINISHED:
            return {
                ...state,
                name: action.name
            };
        case AUTHS_TOKEN_FROM_CODE_STARTED:
            return {
                ...state,
                inProgress: true,
                failed: false,
                codeToTokenFailed: false
            }
        case AUTHS_TOKEN_FROM_CODE_FINISHED:
            return {
                ...state,
                loggedIn: true,
                accessToken: action.accessToken,
                expiresIn: action.expiresIn,
                failed: false,
                inProgress: false
            };
        case AUTHS_TOKEN_FROM_CODE_FAILED:
            return {
                failed: true,
                inProgress: false,
                codeToTokenFailed: true
            };
        default:
            return state
    }
};