import {
    FORGOT_PASSWORD_FAILED,
    FORGOT_PASSWORD_FINISHED,
    FORGOT_PASSWORD_STARTED,
    CONFIRM_PASSWORD_STARTED,
    CONFIRM_PASSWORD_FINISHED,
    CONFIRM_PASSWORD_FAILED,
    FORGOT_PASSWORD_DIALOG_STARTED
} from "./forgotPasswordAction";

const initialState = {
    step: 'enterEmail',
    inProgress: false,
    failed: false,
    email: ''
};

export const forgotPassword = (state=initialState, action) => {
    console.debug({action})
    switch(action.type) {
        case FORGOT_PASSWORD_DIALOG_STARTED:
            return initialState;
        case FORGOT_PASSWORD_STARTED:
            return {
                ...state,
                inProgress: true,
                failed: false
            };
        case FORGOT_PASSWORD_FINISHED:
            return {
                ...state,
                inProgress: false,
                email: action.email,
                step: action.step
            };
        case FORGOT_PASSWORD_FAILED:
            return {
                ...state,
                inProgress: false,
                failed: true
            };
        case CONFIRM_PASSWORD_STARTED:
            return {
                ...state,
                inProgress: true,
                failed: false
            };
        case CONFIRM_PASSWORD_FINISHED:
            return {
                ...state,
                inProgress: false,
                step: action.step
            };
        case CONFIRM_PASSWORD_FAILED:
            return {
                ...state,
                inProgress: false,
                failed: true
            };
        default:
            return state
    }
};