import {v4 as uuidv4} from "uuid";
import queryString from "query-string";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {SET_OAUTH_2_REQUEST_DATA, useLoginDispatch} from "./loginContext";

export const useSyncQueryParams = () => {
    const location = useLocation();
    const dispatch = useLoginDispatch()


    useEffect(() => {
        const {
            response_type='code',
            client_id,
            redirect_uri=window._env_.REACT_APP_DEFAULT_REDIRECT_URI,
            state=uuidv4()
        } = queryString.parse(location.search);

        sessionStorage.setItem('client_id', client_id);
        sessionStorage.setItem('state', state);
        sessionStorage.setItem('redirect_uri', redirect_uri);
        sessionStorage.setItem('response_type', response_type);

        dispatch({
            type: SET_OAUTH_2_REQUEST_DATA,
            payload: {
                clientId: client_id,
                redirectUri: redirect_uri,
                responseType: response_type,
                state: state
            }
        })
    }, []);
}