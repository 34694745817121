import {useDispatch} from "react-redux";
import {refreshToken} from "../login/authsAction";
import {useDeepCompareEffectNoCheck} from "use-deep-compare-effect";

export const useLoggedIn = (redirectUri) => {
    const dispatch = useDispatch();

    useDeepCompareEffectNoCheck(() => {
        if(redirectUri) {
            dispatch(refreshToken(redirectUri));
        }
    }, []);
};