import React, {useState} from "react";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {Form} from "../form/Form";
import {TextFieldForm} from "../form/TextFieldForm";

export const UserdataForm = ({disabled, t}) => {
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showRepeatPassword, setShowRepeatPassword] = useState(false)

    return (
        <Form
            disabled={disabled}
        >
            <TextFieldForm
                formKey={'username'}
                label={t('email', 'E-post')}
                ariaLabel={t('emailHelperText', 'Skriv inn e-posten din')}
                fullWidth={true}
                autoFocus={true}
                disabled={disabled}
                sx={{
                    '& input': {
                        textTransform: 'lowercase'
                    }
                }}
            />
            <TextFieldForm
                formKey={'givenName'}
                label={t('givenName', 'Fornavn', {ns: 'login.newUser'})}
                ariaLabel={t('givenNameHelperText', 'Skriv inn fornavnet ditt', {ns: 'login.newUser'})}
                fullWidth={true}
                disabled={disabled}
                sx={{
                    mt: 2
                }}
            />
            <TextFieldForm
                formKey={'familyName'}
                label={t('familyName', 'Etternavn', {ns: 'login.newUser'})}
                ariaLabel={t('givenNameHelperText', 'Skriv inn etternavnet ditt', {ns: 'login.newUser'})}
                fullWidth={true}
                disabled={disabled}
                sx={{
                    mt: 2
                }}
            />
            <TextFieldForm
                sx={{
                    mt: 2
                }}
                formKey={'newPassword'}
                label={t('title', 'Nytt passord', {ns: 'login.passwordForm'})}
                ariaLabel={t('helperText', 'Skriv inn et ønsket passord', {ns: 'login.passwordForm'})}
                fullWidth={true}
                type={showNewPassword ? 'text' : 'password'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowNewPassword(!showNewPassword)}
                                onMouseDown={e => e.preventDefault()}
                                tabIndex={-1}
                                size="large">
                                {showNewPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                disabled={disabled}
            />
            <TextFieldForm
                sx={{
                    mt: 2
                }}
                formKey={'repeatPassword'}
                label={t('repeatTitle', 'Gjenta nytt passord', {ns: 'login.passwordForm'})}
                ariaLabel={t('repeatHelperText', 'Gjenta nøyaktig det samme passordet', {ns: 'login.passwordForm'})}
                fullWidth={true}
                type={showRepeatPassword ? 'text' : 'password'}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                                onMouseDown={e => e.preventDefault()}
                                tabIndex={-1}
                                size="large">
                                {showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                disabled={disabled}
            />
        </Form>
    )
}