import {createContext, useContext, useReducer} from "react";
import {useTranslation} from "react-i18next";
import {ProgressIndicator} from "../app/ProgressIndicator";

const FormStateContext  = createContext(undefined)
const FormDispatchContext  = createContext(undefined)
const FormTranslateContext  = createContext(undefined)

export const FORM_INITIALIZED = 'form/init'
export const FORM_VALUE_UPDATED = 'form/valueUpdated'
export const FORM_SUBMITTED = 'form/submitted'
export const FORM_SAVED = 'form/saved'
export const FORM_VALUE_ERROR = 'form/valueError'
export const FORM_VALUE_NO_ERROR = 'form/valueNoError'
export const FORM_STEP_CHANGED = 'form/stepChanged'
export const CONTENT_UPDATED = 'form/contentUpdated'
export const CONTENT_UNDO = 'form/contentUndo'
export const CONTENT_REDO = 'form/contentRedo'

const initialState = {
    initialFormData: {},
    formData: {},
    initialized: false,
    isSubmitting: false,
    errors: {},
    step: null,
    modified: false,
    activeIndex: 0,
    contentHistory: [],
    content: {},
    savedIndex: 0,
    modificationCounter: 0,
    manualSaveCount: 0
}

const formReducer = (state, action) => {
    console.debug(action)
    switch(action.type) {
        case FORM_INITIALIZED:
            return {
                ...state,
                formData: action.initialFormData,
                initialFormData: action.initialFormData,
                initialized: true,
                step: 0,
                contentHistory: action.initialContent ? [action.initialContent] : [],
                content: action.initialContent
            }
        case FORM_VALUE_UPDATED:
            return {
                ...state,
                modified: true,
                isSubmitting: false,
                formData: {
                    ...state.formData,
                    [action.key]: action.value
                },
                modificationCounter: state.modificationCounter + 1
            }
        case CONTENT_UPDATED:
            return {
                ...state,
                activeIndex: state.activeIndex + 1,
                contentHistory: [
                    ...state.contentHistory.slice(0, state.activeIndex + 1),
                    action.content
                ],
                content: action.content,
                modificationCounter: state.modificationCounter + 1
            }
        case CONTENT_UNDO:
            return {
                ...state,
                activeIndex: state.activeIndex - 1,
                content: state.contentHistory[state.activeIndex - 1]
            }
        case CONTENT_REDO:
            return {
                ...state,
                activeIndex: state.activeIndex + 1,
                content: state.contentHistory[state.activeIndex + 1]
            }
        case FORM_SUBMITTED: {
            state.onSubmit(state.formData, state.content)
            return {
                ...state,
                isSubmitting: true,
                manualSaveCount: action.autoSave ? state.manualSaveCount : state.manualSaveCount + 1
            }
        }

        case FORM_SAVED: {
            let updatedFormData = {}
            if(action.key && action.value) {
                updatedFormData = {
                    ...updatedFormData,
                    [action.key]: action.value,
                    createdAt: new Date()
                }
            }
            return {
                ...state,
                isSubmitting: false,
                formData: {
                    ...state.formData,
                    ...updatedFormData,
                },
                modified: false,
                savedIndex: state.activeIndex
            }
        }

        case FORM_VALUE_ERROR: {
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.key]: action.exception.errors[0]
                }
            }
        }
        case FORM_VALUE_NO_ERROR: {
            const {[action.key]: remove, ...updatedErrors} = state.errors
            return {
                ...state,
                errors: updatedErrors
            }
        }
        case FORM_STEP_CHANGED:
            return {
                ...state,
                step: action.step
            }
        default:
            throw new Error(`Unhandled action type ${action.type}`)
    }
}

export const FormProvider = ({onSubmit, schema=null, children}) => {
    const [state, dispatch] = useReducer(
        formReducer,
        {
            ...initialState,
            onSubmit: onSubmit,
            schema: schema
        },
        undefined
    )
    const {t, ready} = useTranslation('vm-admin.form', {useSuspense: false})

    if(!ready) {
        return <ProgressIndicator />
    } else {
        return (
            <FormStateContext.Provider value={state}>
                <FormDispatchContext.Provider value={dispatch}>
                    <FormTranslateContext.Provider value={t}>
                        {children}
                    </FormTranslateContext.Provider>
                </FormDispatchContext.Provider>
            </FormStateContext.Provider>
        )
    }

}

export const useFormState = () => {
    const context = useContext(FormStateContext)

    if(undefined === context) {
        throw new Error("useFormState must be used within a FormProvider");
    } else {
        return context
    }
}

export const useFormDispatch = () => {
    const context = useContext(FormDispatchContext)

    if(undefined === context) {
        throw new Error("useFormDispatch must be used within a FormProvider");
    } else {
        return context
    }
}

export const useFormTranslation = () => {
    const context = useContext(FormTranslateContext)

    if(undefined === context) {
        throw new Error("useFormTranslation must be used within a FormProvider");
    } else {
        return context
    }
}