import {useEffect, useState} from 'react';

export const useAbortController = () => {
    const [abortController] = useState(new AbortController());

    useEffect(() => {
        return () => abortController.abort();
    }, [abortController]);

    return abortController;
};