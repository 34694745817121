import React, {useEffect} from 'react';
import {UserdataStep} from "./steps/UserdataStep";
import {CodeVerificationStep} from "./steps/CodeVerificationStep";
import {useParams} from 'react-router-dom';
import {ResendCodeStep} from "./steps/ResendCodeStep";
import {SuccessStep} from "./steps/SuccessStep";
import {useDispatch, useSelector} from "react-redux";
import {resendCode, startSignUpGuide} from "./signUpAction";

export const Signup = () => {
    const signUpState = useSelector(state => state.signUp);
    const dispatch = useDispatch();
    const params = useParams();

    useEffect(() => {
        dispatch(startSignUpGuide())
    }, [dispatch]);

    useEffect(() => {
        if(params.email) {
            dispatch(resendCode(params.email));
        }
    }, [params, dispatch]);

    switch(signUpState.step) {
        case 'userdata':
            return (
                <UserdataStep />
            );
        case 'resendCode':
            return (
                <ResendCodeStep />
            );
        case 'codeVerification':
            return (
                <CodeVerificationStep />
            );
        case 'success':
            return (
                <SuccessStep />
            );
        default:
            return null
    }
};