import React from 'react';
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {resendConfirmationCode} from "../signUpAction";
import {Stack} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import LoadingButton from "@mui/lab/LoadingButton";
import {Send} from "@mui/icons-material";
import {useLoginTranslation} from "../../login/loginContext";

export const ResendCodeStep = () => {
    const t = useLoginTranslation()
    const dispatch = useDispatch();
    const signUpState = useSelector(state => state.signUp);


    const clickHandler = () => {
        dispatch(resendConfirmationCode(signUpState.email));
    };

    return (
        <Stack
            spacing={2}
            alignItems={"stretch"}
            sx={{
                width: "100%",
                mt: 2
            }}
        >
            <Typography variant='subtitle1' color='textPrimary'>
                {t('login.newUser:verifyAccount', 'Verifiser kontoen din')}
            </Typography>
            <Typography>
                {t('email', 'E-post')}: {signUpState.email}
            </Typography>
            <FormHelperText
                error={signUpState.failed}
            >
                {signUpState.failed ?
                    t('sendNewCodeError', 'Vi klarte ikke å sende deg en ny kode. Prøv igjen om en liten stund. ' +
                        'Om problemet vedvarer ta kontakt med support.', {ns: 'login.newUser'})
                    : ''
                }
            </FormHelperText>
            <LoadingButton
                type="submit"
                onClick={clickHandler}
                disabled={signUpState.inProgress}
                variant={"contained"}
                startIcon={<Send />}
                loading={signUpState.inProgress}
                loadingPosition={"start"}
                sx={{
                    mt: 2
                }}
            >
                {t('sendNewCodeButton', 'Send meg en ny kode', {ns: 'login.newUser'})}
            </LoadingButton>
        </Stack>
    )
}