import {useLocation, useNavigate} from 'react-router-dom';
import queryString from 'query-string'
import React, {useEffect} from "react";
import {useAbortController} from "../hooks/useAbortController";
import {useDispatch, useSelector} from "react-redux";
import Box from "@mui/material/Box";
import {getTokenFromCode} from "../login/authsAction";
import {ProgressIndicator} from "../app/ProgressIndicator";
import {useLoginState} from "../login/loginContext";

export const Callback = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate()
    const abortController = useAbortController();
    const {
        state,
        redirectUri
    } = useLoginState()
    const loggedIn = useSelector(state => state.auths.loggedIn);
    const tokenFailed = useSelector(state => state.auths.codeToTokenFailed);

    useEffect(() => {
        const searchParams = queryString.parse(location.search);

        if(state && state !== searchParams.state) {
            console.log('Possible CSRF attack detected!');
            navigate('/');
        } else if(state){
            dispatch(getTokenFromCode({
                code: searchParams.code,
                redirectUri: redirectUri
            }))
        }
    }, [location.search, abortController.signal, navigate, state, redirectUri, dispatch]);

    if(loggedIn) {
        navigate('/welcome')
    } else if(tokenFailed) {
        navigate('/')
    }

    return (
        <Box>
            <ProgressIndicator />
        </Box>
    )
};