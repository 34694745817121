import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {EnterEmailStep} from "./steps/EnterEmailStep";
import {CodeAndPasswordStep} from "./steps/CodeAndPasswordStep";
import {SuccessStep} from "./steps/SuccessStep";
import {useDispatch, useSelector} from "react-redux";
import {setEmail, startForgotPasswordGuide} from "../forgotPasswordAction";

export const ForgotPasswordDialog = () => {
    const dispatch = useDispatch();
    const forgotPasswordState = useSelector(state => state.forgotPassword);
    const {initialEmail} = useParams();


    useEffect(() => {
        dispatch(startForgotPasswordGuide())
    }, [dispatch]);

    useEffect(() => {
        dispatch(setEmail(initialEmail));
    }, [initialEmail, dispatch]);


    const renderStep = () => {
        switch(forgotPasswordState.step) {
            case 'enterEmail':
                return <EnterEmailStep />;
            case 'codeAndPassword':
                return (
                    <CodeAndPasswordStep />
                );
            case 'success':
                return (
                    <SuccessStep/>
                );
            default:
                return null
        }
    }



    return (
        renderStep()
    )
};