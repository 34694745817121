import React from 'react';
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {confirmPassword} from "../../forgotPasswordAction";
import {FormProvider} from "../../../form/formContext";
import {Stack} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import {FormSubmit} from "../../../form/FormSubmit";
import {Send} from "@mui/icons-material";
import {CodeAndPasswordForm} from "../CodeAndPasswordForm";
import * as Yup from "yup";
import {useLoginTranslation} from "../../loginContext";

export const CodeAndPasswordStep = () => {
    const dispatch = useDispatch();
    const forgotPasswordState = useSelector(state => state.forgotPassword)
    const t = useLoginTranslation()


    const submitHandler = formData => {
        console.debug({formData})
        dispatch(confirmPassword({
            email: forgotPasswordState.email,
            verificationCode: formData.code,
            newPassword: formData.newPassword
        }));
    }

    const validationSchema = Yup.object().shape({
        code: Yup.string()
            .required(
                t('codeHelperText', 'Skriv inn engangskoden du har motatt.', {ns: 'login.passwordForm'})
            ),
        newPassword: Yup.string()
            .required(
                t('passwordHelperText', 'Skriv inn passordet ditt', {ns: 'login.passwordForm'})
            )
            .min(
                8,
                t('tooShort', 'Passordet må være minimum {{minLength}} tegn', {minLength: 8, ns: 'login.passwordForm'})
            )
            .matches(
                window._env_.REACT_APP_PASSWORD_PATTERN,
                t('patternMismatch', 'Passordet må inneholde store og små bokstaver og tall.', {ns: 'login.passwordForm'})
            ),
        repeatPassword: Yup.string()
            .required(
                t('repeatHelperText', 'Gjenta nøyaktig det samme passordet', {ns: 'login.passwordForm'})
            )
            .oneOf([Yup.ref('newPassword'), null],
                t('repeatMismatch', 'Passordene er ikke like', {ns: 'login.passwordForm'})
            )
    })

    return (
        <FormProvider
            schema={validationSchema}
            onSubmit={submitHandler}
        >
            <Stack
                spacing={2}
                alignItems={"stretch"}
                sx={{
                    width: "100%",
                    mt: 2
                }}
            >
                <Typography variant={'h4'}>
                    {t(
                        'title',
                        'Nytt passord',
                        {
                            ns: 'login.newPassword'
                        }
                    )}
                </Typography>
                <Typography>
                    {t(
                        'subtitle',
                        'Vi har sendt deg en epost med en kode til {{email}}. Skriv inn koden under for å sette et nytt passord.',
                        {
                            email: forgotPasswordState.email,
                            ns: 'login.newPassword'
                        }
                    )}
                </Typography>

                <CodeAndPasswordForm
                    t={t}
                    disabled={false}
                    email={forgotPasswordState.email}
                />
                <FormHelperText
                    error={forgotPasswordState.failed}
                >
                    {forgotPasswordState.failed ?
                        t(
                            'changePasswordHelperText',
                            'Vi klarte ikke å endre passordet ditt. Sørg for at koden er riktig.',
                            {
                                ns: 'login.passwordForm'
                            }
                        ) : ''
                    }
                </FormHelperText>
                <FormSubmit
                    loadingButton={true}
                    loading={forgotPasswordState.inProgress}
                    loadingPosition={"start"}
                    disabled={forgotPasswordState.inProgress}
                    variant={"contained"}
                    startIcon={<Send />}
                    sx={{
                        mt: 2
                    }}
                >
                    {t(
                        'changePasswordButton',
                        'Endre passord',
                        {
                            ns: 'login.passwordForm'
                        }
                    )}
                </FormSubmit>
            </Stack>
        </FormProvider>
    )
}