import {useSamlState} from "./samlContext";
import {Box, Stack, Typography} from "@mui/material";
import {UsernameForm} from "./UsernameForm";
import {useSAMLIdp} from "./useSAMLIdp";
import {FORM_VALUE_UPDATED, useFormDispatch, useFormState} from "../form/formContext";
import {useIdps} from "./useIdps";
import {Fragment, useEffect} from "react";
import {useLoginTranslation} from "./loginContext";

export const WithSamlIdp = ({children}) => {
    const {
        domain,
        specificDomain
    } = useSamlState()
    const {
        formData,
        errors,
    } = useFormState()
    const formDispatch = useFormDispatch()
    const t = useLoginTranslation()

    useEffect(
        () => {
            formDispatch({
                type: FORM_VALUE_UPDATED,
                key: 'samlDomain',
                value: domain
            })
        },
        [domain, formDispatch]
    )

    useIdps()
    useSAMLIdp({
        email: formData.username,
        valid: Boolean(formData.username !== undefined && !errors.username)
    })

    if(domain) {
        return (
            <Box>
                <Typography variant='subtitle1' color='textPrimary'>
                    {t('samlHelperText', 'Du blir logget inn med sentral identitetsleverandør knyttet til {{provider}}', {provider: specificDomain})}
                </Typography>
                <Stack
                    alignItems={"stretch"}
                    sx={{
                        width: "100%",
                        mt: 2
                    }}
                >
                    <UsernameForm
                        t={t}
                    />
                </Stack>
            </Box>
        )
    } else {
        return (
            <Fragment>
                {children}
            </Fragment>
        )
    }
}