import {useDispatch, useSelector} from "react-redux";
import * as Yup from "yup";
import {confirmPassword} from "../login/forgotPasswordAction";
import {Box, Stack, Typography} from "@mui/material";
import {FormProvider} from "../form/formContext";
import {CodeAndPasswordForm} from "../login/reset-password/CodeAndPasswordForm";
import FormHelperText from "@mui/material/FormHelperText";
import {FormSubmit} from "../form/FormSubmit";
import {Send} from "@mui/icons-material";
import React from "react";
import {useLoginTranslation} from "../login/loginContext";

export const ResetPasswordStep = ({email}) => {
    const dispatch = useDispatch();
    const forgotPasswordState = useSelector(state => state.forgotPassword)
    const t = useLoginTranslation()

    const validationSchema = Yup.object().shape({
        code: Yup.string()
            .required(
                t('codeHelperText', 'Skriv inn engangskoden du har motatt.', {ns: 'login.passwordForm'})
            ),
        newPassword: Yup.string()
            .required(
                t('passwordHelperText', 'Skriv inn passordet ditt', {ns: 'login.passwordForm'})
            )
            .min(
                8,
                t('tooShort', 'Passordet må være minimum {{minLength}} tegn', {minLength: 8, ns: 'login.passwordForm'})
            )
            .matches(
                window._env_.REACT_APP_PASSWORD_PATTERN,
                t('patternMismatch', 'Passordet må inneholde store og små bokstaver og tall.', {ns: 'login.passwordForm'})
            ),
        repeatPassword: Yup.string()
            .required(
                t('repeatHelperText', 'Gjenta nøyaktig det samme passordet', {ns: 'login.passwordForm'})
            )
            .oneOf([Yup.ref('newPassword'), null],
                t('repeatMismatch', 'Passordene er ikke like', {ns: 'login.passwordForm'})
            )
    })

    const submitHandler = formData => {
        dispatch(confirmPassword({
            email: email,
            verificationCode: formData.code,
            newPassword: formData.newPassword
        }))
    }

    return (
        <Box>
            <Typography variant={'h4'}>
                {t(
                    'title',
                    'Nytt passord',
                    {
                        ns: 'login.newPassword'
                    }
                )}
            </Typography>
            <Typography variant='subtitle1' color='textPrimary'>
                {t(
                    'subtitle',
                    'Du må endre passordet ditt før du kan ta i bruk kontoen. En kode er sendt til din e-postadresse.',
                    {
                        ns: 'login.newPassword'
                    }
                )}

            </Typography>
            <FormProvider
                schema={validationSchema}
                onSubmit={submitHandler}
            >
                <Stack
                    spacing={2}
                    alignItems={"stretch"}
                    sx={{
                        width: "100%",
                        mt: 2
                    }}
                >
                    <CodeAndPasswordForm
                        t={t}
                        disabled={false}
                        email={email}
                    />
                    <FormHelperText
                        error={forgotPasswordState.failed}
                    >
                        {forgotPasswordState.failed ?
                            t(
                                'changePasswordHelperText',
                                'Vi klarte ikke å endre passordet ditt. Sørg for at koden er riktig.',
                                {
                                    ns: 'login.passwordForm'
                                }
                            ) : ''
                        }
                    </FormHelperText>
                    <FormSubmit
                        loadingButton={true}
                        loading={forgotPasswordState.inProgress}
                        loadingPosition={"start"}
                        disabled={forgotPasswordState.inProgress}
                        variant={"contained"}
                        startIcon={<Send />}
                        sx={{
                            mt: 2
                        }}
                    >
                        {t(
                            'changePasswordButton',
                            'Endre passord',
                            {
                                ns: 'login.passwordForm'
                            }
                        )}
                    </FormSubmit>
                </Stack>
            </FormProvider>
        </Box>
    )
}