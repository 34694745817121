export const AUTHS_TOKEN_REFRESH_STARTED = 'auths/refreshTokenStarted';
export const AUTHS_TOKEN_REFRESHED = 'auths/tokenRefreshed';
export const AUTHS_TOKEN_REFRESH_FAILED = 'auths/refreshTokenFailed';
export const AUTHS_GET_USER_INFO_STARTED = 'auths/getUserInfoStarted';
export const AUTHS_GET_USER_INFO_FINISHED = 'auths/getUserInfoFinished';
export const AUTHS_GET_USER_INFO_FAILED = 'auths/getUserInfoFailed';
export const AUTHS_TOKEN_FROM_CODE_STARTED = 'auths/tokenFromCodeStarted';
export const AUTHS_TOKEN_FROM_CODE_FINISHED = 'auths/tokenFromCodeFinished';
export const AUTHS_TOKEN_FROM_CODE_FAILED = 'auths/tokenFromCodeFailed';

export const getTokenFromCode = ({code, redirectUri}) => dispatch => {
    dispatch({type: AUTHS_TOKEN_FROM_CODE_STARTED});
    const data = new URLSearchParams();
    data.append('code', code);
    data.append('grant_type', 'authorization_code');
    data.append('redirect_uri', redirectUri);

    fetch(`${window._env_.REACT_APP_APIGATEWAY}/auths/oauth2/token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        credentials: 'include',
        body: data,
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error(response.statusText)
            }
        })
        .then(data => dispatch({
            type: AUTHS_TOKEN_FROM_CODE_FINISHED,
            accessToken: data.access_token,
            expiresIn: data.expires_in
        }))
        .catch(error => {
            console.log(error);
            dispatch({type: AUTHS_TOKEN_FROM_CODE_FAILED})
        })
};

export const refreshToken = redirectUri => dispatch => {
    dispatch({type: AUTHS_TOKEN_REFRESH_STARTED});

    const data = new URLSearchParams();
    data.append('grant_type', 'refresh_token');
    data.append('redirect_uri', redirectUri);

    fetch(`${window._env_.REACT_APP_APIGATEWAY}/auths/oauth2/token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        credentials: 'include',
        body: data,
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error(response.statusText)
            }
        })
        .then(data =>dispatch({
            type: AUTHS_TOKEN_REFRESHED,
            accessToken: data.access_token,
            expiresIn: data.expires_in
        }))
        .catch(error => dispatch({type: AUTHS_TOKEN_REFRESH_FAILED}))
};

export const getUserInfo = accessToken => dispatch => {
    dispatch({type: AUTHS_GET_USER_INFO_STARTED});

    const userInfoUrl = `${window._env_.REACT_APP_APIGATEWAY}/auths/oauth2/userInfo`;

    fetch(userInfoUrl, {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        },
        credentials: 'include'
    })
        .then(response => response.json())
        .then(data => dispatch({
            type: AUTHS_GET_USER_INFO_FINISHED,
            name: data.name
        }))
        .catch(error => {
            console.log(error.message);
            dispatch({type: AUTHS_GET_USER_INFO_FAILED})
        })
};